import Vue from "vue";
import VueRouter from "vue-router";
// import EmptyRouterView from '@/views/layouts/EmptyRouterView.vue';
import { authRouter } from "../modules/auth/auth.router.js";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  ...authRouter,

  {
    path: "/",
    component: () => import("@/views/layouts/DefaultLayout.vue"),
    meta: {
      authOnly: true,
    },
    children: [
      {
        path: "/dashboard",
        alias: "/",
        name: "dashboard",
        component: () => import(/* webpackChunkName: "about" */ "@/views/pages/dashboard/Dashboard.vue"),
        meta: {},
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

function isLoggedIn() {
  return !!store.state.auth.accessToken;
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isLoggedIn()) {
      next({
        path: "/auth/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.guestOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (isLoggedIn()) {
      next({
        path: "/",
        query: {},
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
