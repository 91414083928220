if (!process.env.VUE_APP_API_URL) {
  // eslint-disable-next-line no-alert
  alert("Переменная API_URL не установленна");
  window.stop();
}

export const API_URL = process.env.VUE_APP_API_URL;
export const API_ERROR_MESSAGE = "Ошибка выполнения";

export default {
  API_URL,
  API_ERROR_MESSAGE,
};
