import { Http } from "@/plugins/http.js";
import { API_URL } from "@/config.js";

export default {
  namespaced: true,

  state: {
    profile: null,
    accessToken: "",
  },

  actions: {
    async login({ commit }, form) {
      try {
        const { data } = await Http.post(`${API_URL}/core-auth/login`, form);

        if (data.success) {
          const token = `Bearer ${data.token}`;

          localStorage.setItem("accessToken", token);
          commit("SET_ACCESS_TOKEN", token);
          commit("SET_PROFILE", data.data);

          return { success: true };
        }

        return { success: false, message: data.message };
      } catch (e) {
        console.log("err", e);
      }

      return { success: false, message: "Что-то пошло не так:-( Свяжитесь с администрацией." };
    },

    loadToken({ commit }) {
      const token = localStorage.getItem("accessToken");
      if (token) {
        commit("SET_ACCESS_TOKEN", token);
      }
    },

    async loadProfile({ commit, state }) {
      if (state.profile !== null) {
        return;
      }

      // Если токена нету, то профиль получить не получится
      if (state.accessToken === "") {
        return;
      }

      try {
        const { data } = await Http.get(`${API_URL}/core-auth`, {
          headers: { Authorization: state.accessToken },
        });

        if (data.success) {
          commit("SET_PROFILE", data.data);
        }
      } catch (e) {
        commit("CLEAR_AUTH");
        console.log("err", e);
      }
    },
  },

  mutations: {
    SET_ACCESS_TOKEN(state, token) {
      state.accessToken = token;
    },

    SET_PROFILE(state, profile) {
      state.profile = profile;
    },

    CLEAR_AUTH(state) {
      state.profile = null;
      state.accessToken = "";
    },
  },
};
