import store from "@/store";

export const NotificationPlugin = {
  install(vue) {
    vue.prototype.$notify = {
      /**
       * @param {String} text
       * @param {NotificationType} type
       * @param {number} timeout
       */
      showMessage(text, type, timeout = 5) {
        store.commit("notification/SHOW_MESSAGE", {
          text,
          color: type,
          timeout: timeout * 1000,
        });
      },
    };
  },
};
