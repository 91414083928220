export const authRouter = [
  {
    path: "/auth",
    component: () => import("@/modules/auth/AuthLayout.vue"),
    meta: {
      guestOnly: true,
    },
    children: [
      {
        path: "login",
        name: "login",
        component: () => import(/* webpackChunkName: "appointment" */ "@/modules/auth/pages/login.page.vue"),
        meta: {
          title: "Логин",
          guestOnly: true,
        },
      },
    ],
  },
];
