import axios from "axios";
import { API_URL } from "@/config";
import store from "@/store";
import router from "@/router";

const Http = axios.create();
Http.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

Http.interceptors.request.use(
  config => {
    // Проверка URL для автоподстановки ссылки на API сервер
    if (config.url.startsWith("v1/") || config.url.startsWith("v2/")) {
      config.url = `${API_URL}/${config.url}`;
    }

    if (store.state.auth.accessToken) {
      config.headers.Authorization = store.state.auth.accessToken;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

// Add a response interceptor
Http.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  // remember to make this async as the store action will
  // need to be awaited
  async function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const originalRequest = error.config;

    if (error.response.status === 401 && router.currentRoute.name !== "login") {
      await router.push("/auth/login");
    }
    /*else if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      // await execution of the store async action before
      return axios(originalRequest);
    }*/

    return Promise.reject(error);
  },
);

const HttpPlugin = {
  install(vue) {
    vue.prototype.$http = Http;
  },
};

export { Http, HttpPlugin };
