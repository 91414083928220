import Vue from "vue";
import Vuex from "vuex";
import { NotificationStoreModule } from "@/plugins/notifications/notification-store-module";
import reportFilterModule from "./report-filter.module";
import authModule from "@/modules/auth/auth.store.js";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth: authModule,
    reportFilter: reportFilterModule,

    // plugins
    [NotificationStoreModule.name]: NotificationStoreModule,
  },
});

export default store;
