import Vue from "vue";
import Vuelidate from "vuelidate";

import "./style.scss";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { HttpPlugin } from "./plugins/http";
import { DatePlugin } from "./plugins/date";
import { NotificationPlugin } from "./plugins/notifications/notification";
import "@/views/components/modals";
import { ConfigPlugin } from "./plugins/config.js";

Vue.config.productionTip = false;

// Активация плагинов
Vue.use(ConfigPlugin);
Vue.use(HttpPlugin);
Vue.use(DatePlugin);
Vue.use(Vuelidate);
Vue.use(NotificationPlugin);

(async () => {
  await store.dispatch("auth/loadToken");

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),

    async created() {
      await store.dispatch("auth/loadProfile");
    },
  }).$mount("#app");
})();
