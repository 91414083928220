// import '@mdi/font/css/materialdesignicons.css';
import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";

import ru from "vuetify/lib/locale/ru";
import en from "vuetify/lib/locale/en";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "md", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  lang: {
    locales: { ru, en },
    current: "ru",
  },
});
