<template>
  <router-view />
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({}),

  created() {},
};
</script>

<style scoped lang="scss"></style>
